import React, { Fragment, useState, useContext } from "react";
import { CategoryContext } from "./index";
import { ThemeContext } from "../theme/ThemeContext";
import { createCategory, getAllCategory } from "./FetchApi";

const AddCategoryModal = () => {
  const { data, dispatch } = useContext(CategoryContext);
  const { darkMode , setDM } = useContext(ThemeContext)

  const darkfield = darkMode ? 'focus:border-[#2D9596] focus:border-2 bg-gray-700' : 'focus:border-black focus:border-2 bg-white border'
  const darkbtn = darkMode ? 'bg-[#2D9596] text-white' : 'bg-[#303031] text-gray-100'
  const darkModal = darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );
  const [fData, setFdata] = useState({
    nameCategory: "",
    description: "",
    status: "Active",
    success: false,
    error: false,
  });
  const fetchData = async () => {
    let responseData = await getAllCategory();
    if (responseData.Categories) {
      dispatch({
        type: "fetchCategoryAndChangeState",
        payload: responseData.Categories,
      });
    }
  };

  if (fData.error || fData.success) {
    setTimeout(() => {
      setFdata({ ...fData, success: false, error: false });
    }, 3000);
  }

  const submitForm = async (e) => {
    dispatch({ type: "loading", payload: true });
    // Reset and prevent the form
    e.preventDefault();
    e.target.reset();
  
    if (!fData.nameCategory) {
      dispatch({ type: "loading", payload: false });
      return setFdata({ ...fData, error: "Please Add the name !" });
    }
  
    try {
      let responseData = await createCategory(fData);
      if (responseData && responseData.success) {
        fetchData();
        setFdata({
          ...fData,
          nameCategory: "",
          description: "",
          status: "Active",
          success: responseData.success,
          error: false,
        });
        dispatch({ type: "loading", payload: false });
        setFdata({
          ...fData,
          nameCategory: "",
          description: "",
          status: "Active",
          success: "Add Category Successfully :3",
          error: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } else if (responseData && responseData.error) {
        dispatch({ type: "loading", payload: false });
        setFdata({ ...fData, error: responseData.error });
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "addCategoryModal", payload: false })}
        className={`${
          data.addCategoryModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}
      {/* Modal Start */}
      <div
        className={`${
          data.addCategoryModal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className={`relative ${darkModal}  w-12/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8`}>
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Category
            </span>
            {/* Close Modal */}
            <span
              onClick={(e) =>
                dispatch({ type: "addCategoryModal", payload: false })
              }
              className={`cursor-pointer ${darkbtn} py-2 px-2 rounded-full`}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex flex-col space-y-1 w-full py-4">
              <label htmlFor="name">Category Name</label>
              <input
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    nameCategory: e.target.value,
                  })
                }
                value={fData.nameCategory}
                className={`px-4 py-2 focus:outline-none ${darkfield}`}
                type="text"
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="description">Category Description</label>
              <textarea
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    description: e.target.value,
                  })
                }
                value={fData.description}
                className={`px-4 py-2 focus:outline-none ${darkfield}`}
                name="description"
                id="description"
                cols={5}
                rows={5}
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="status">Category Status</label>
              <select
                name="status"
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    status: e.target.value,
                  })
                }
                className={`px-4 py-2 focus:outline-none ${darkfield}`}
                id="status"
              >
                <option name="status" value="Active">
                  Active
                </option>
                <option name="status" value="Inactive">
                  Inactive
                </option>
              </select>
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                type="submit"
                className={`${darkbtn} rounded-full text-lg font-medium py-2`}
              >
                Create category
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddCategoryModal;
